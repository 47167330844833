import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>
                <a
                    className="dev-name"
                    href="https://novaquach.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Nova
                </a>
                <a
                    className="dev-name"
                    href="http://fawadcodes.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Fawad
                </a>
                <a
                    className="dev-name"
                    href="http://xiaogedeng.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Xiaoge
                </a>
                &copy;2019
            </p>
        </footer>
    );
};

export default Footer;
