
export const stockWords = [
    'capital',
    'mind',
    'meet',
    'storey',
    'lute',
    'phase',
    'praise',
    'pried',
    'see',
    'moat',
    'sealing',
    'chili',
    'straight',
    'lea',
    'genes',
    'flair',
    'faint',
    'yolk',
    'troop',
    'alter',
    'cokes',
    'draft',
    'reek',
    'rough',
    'vail',
    'colonel',
    'chords',
    'rye',
    'lichens',
    'sack',
    'their',
    'coup',
    'bail',
    'peace',
    'piers',
    'pour',
    'bridal',
    'whether',
    'creek',
    'effect',
    // new homophones
    'skull',
    'lane',
    'rood',
    'weigh',
    'rest',
    'wheeled',
    'wave',
    'whale',
    'tide',
    'cite',
    'jewel',
    'plane',
    'band',
    'ark',
    'ascent',
    'baron',
    'bowe',
    'canvas',
    'carrot',
    'course',
    'freeze',
    'gorilla',
    'nave',
    'manor',
    'nicks',
    'plate',
    'principle',
    'sucker',
    'stationary',
    'surge',

];

export default stockWords;
