import firebase from 'firebase';

var config = {
    apiKey: 'AIzaSyAaM3cfP4mcEZcgmltnGyMoqlakT2dgIus',
    authDomain: 'novas-piece.firebaseapp.com',
    databaseURL: 'https://novas-piece.firebaseio.com',
    projectId: 'novas-piece',
    storageBucket: 'novas-piece.appspot.com',
    messagingSenderId: '245936779972',
};
firebase.initializeApp(config);

export const leaderboardDbRef = firebase.database().ref('/leaderboard');

export default firebase;
